<template>
  <div class="page">
    <van-nav-bar title="实名认证" left-text="返回" left-arrow @click-left="back"></van-nav-bar>

    <van-form class="edit_form" ref="editForm">
      <van-field
        class="form_input"
        disabled
        v-model="userInfo.username"
        name="username"
        type="text"
        label="真实姓名"
        placeholder="请输入"
        :rules="rules.username"
      />
      <van-field
        class="form_input"
        required
        v-model="userInfo.idNumber"
        name="mobile"
        type="text"
        label="身份证号"
        placeholder="请输入"
        :rules="rules.idNumber"
      ></van-field>
      <div class="form_btn" style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="startVerify">开始验证</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: null,
      timerId: null,
      rules: {
        username: [{ required: true, message: "请输入真实姓名" }],
        idNumber: [{ required: true, message: "请输入身份证号" }],
      },
    };
  },
  methods: {
    startVerify() {
      if (this.userInfo.idNumber == null || this.userInfo.idNumber == "") {
        this.$toast.fail("身份证号不能为空");
        return;
      }
      const toast = this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get("../weixin/getCodeUrl", {
          params: { credId: this.userInfo.idNumber },
        })
        .then((res) => {
          if (res.code == "ok") {
            toast.clear();
            location.href = res.data;
          } else {
            toast.clear();
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          toast.clear();
        });
    },

    back() {
      this.$back();
    },
  },
  mounted() {
    this.userInfo = { ...this.$user };

    var code = this.$route.query.code;
    //如果有code, 开始实名认证
    if (code != null) {
      this.userInfo.idNumber = this.$route.query.state;
      this.$axios
        .get("../weixin/verify", {
          params: {
            openId: this.$user.openId,
            code: code,
            realName: userInfo.username,
            credId: this.userInfo.idNumber,
          },
        })
        .then((res) => {
          if (res.code == "ok") {
            toast.clear();
            location.href = res.data;
          } else {
            toast.clear();
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          toast.clear();
        });
    }
  },
};
</script>

<style lang="less" scoped>
.logout {
  bottom: 0;
  height: 50px;
  color: #ff5f16;
}

/* 本页公共样式 */
.gray {
  color: #797d82;
}

.bott0 {
  bottom: 0;
}

.orange {
  color: #ff5f16;
}

.f15 {
  font-size: 15px;
}

.bd-gray {
  border-bottom: 1px solid #f5f5f5;
}

// 设置
header {
  .city {
    height: 44px;
    line-height: 44px;
  }
}

// 账号ID
.id {
  height: 49px;
  line-height: 49px;
}
</style>
<style lang="less">
.van-nav-bar .van-icon,
.van-nav-bar__text {
  color: #20a0ff;
}
</style>

